<template>
  <div>
    <div style="padding: 15px">{{ $t("Please transfer and upload slip") }}</div>

    <van-cell-group>
      <van-cell
        class="cell-bank"
        :title="$t('Bank')"
        :value="bankInfo && bankInfo.bankName"
      />
      <van-cell :title="$t('Account No.')">
        <template>
          <span>{{ bankInfo && bankInfo.acctNumber }}</span>
          <span class="copy" @click="copyInfo(bankInfo.acctNumber)">{{
            $t("[复制]")
          }}</span>
        </template>
      </van-cell>
      <van-cell
        :title="$t('Account Name')"
        :value="bankInfo && bankInfo.acctName"
      />
      <van-cell :title="$t('Amount')">
        <template>
          <span>฿{{ bankInfo && queryParams.total }}</span>
          <span class="copy" @click="copyInfo(queryParams.total)">{{
            $t("[复制]")
          }}</span>
        </template>
      </van-cell>
    </van-cell-group>

    <div style="padding: 15px; margin-bottom: 60px">
      {{ $t("Bank slip") }}

      <div style="text-align: center; padding: 20px 0px">
        <van-uploader
          v-model="fileList"
          :after-read="afterRead"
          multiple
          preview-size="250"
          :max-count="1"
        />
        <div class="upload-sample" @click.stop="seeSampleSlip">
          {{ $t("See sample slip") }}
        </div>
      </div>
    </div>

    <div class="saveButton">
      <van-button
        @click="doSubmit"
        color="#EE502F"
        style="width: 150px; border-radius: 5px"
        :loading="submitLoading"
        :disabled="submitLoading"
      >
        {{ $t("确定") }}
      </van-button>
    </div>
  </div>
</template>

<script>
import { Toast, Cell, CellGroup, Uploader, Button, ImagePreview } from "vant";
import { getConfig, setConfig } from "@/utils/token";
import { uploadSlip, fetchConfig } from "./service";
import { getChatWoot } from "@/utils/storage";
import { PAYMENTITEM, SUCCESS } from "@/utils/constants";
import { trackCompletePayment } from "@/utils/statistic";

import slip_sample_1 from "@/assets/images/slip_sample_1.jpg";
import slip_sample_2 from "@/assets/images/slip_sample_2.jpg";

export default {
  components: {
    [Uploader.name]: Uploader,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Button.name]: Button,
  },
  data() {
    return {
      submitLoading: false,
      bankInfo: null,
      fileList: [],
      file: null,
      imageUrl: null,
      queryParams: this.$route.query,
    };
  },
  mounted() {
    const config = getConfig();
    if (config) {
      this.bankInfo =
        this.queryParams.paymentItem == PAYMENTITEM.b2b_freight
          ? config.logisticAccount
          : config.orderAccount;
    }

    if (!this.bankInfo) {
      // Toast.fail("Bank info get failed,please try later...");
      this.fetchConfig();
    }
  },
  methods: {
    async fetchConfig() {
      Toast.loading({
        duration: 0,
        forbidClick: true,
        message: this.$t("加载中..."),
      });
      const res = await fetchConfig();
      const { success, data } = res;
      Toast.clear();

      if (success) {
        setConfig(data);

        if (data) {
          this.bankInfo =
            this.queryParams.paymentItem == PAYMENTITEM.b2b_freight
              ? data.logisticAccount
              : data.orderAccount;

          if (!this.bankInfo) {
            Toast.fail("Bank info get failed,please try later...");
          }
        }
      }
    },
    seeSampleSlip() {
      ImagePreview([
        this.queryParams.paymentItem == PAYMENTITEM.b2b_freight
          ? slip_sample_2
          : slip_sample_1,
      ]);
    },
    doSubmit() {
      if (!this.file || !this.fileList || this.fileList.length == 0) {
        Toast.fail(this.$t("上传网银截图"));
        return;
      }

      this.uploadScreenshot();
    },
    async uploadScreenshot() {
      let formData = new FormData();
      const { orderIds, orderNames, paymentItem, expressWay, couponId } =
        this.queryParams;
      formData.append("orderId", orderIds);
      formData.append("orderName", orderNames);
      formData.append("paymentItem", paymentItem);
      formData.append("file", this.file.file);

      const chatwoot = getChatWoot();
      if (chatwoot) {
        const { chatChannel, chatConversationId, chatUserId } = chatwoot;
        formData.append("chatChannel", chatChannel);
        formData.append("chatConversationId", chatConversationId);
        formData.append("chatUserId", chatUserId);
      }

      if (paymentItem == PAYMENTITEM.b2b_freight) {
        formData.append("expressWay", expressWay);
        formData.append("couponId", couponId);
      }

      this.submitLoading = true;

      const res = await uploadSlip(formData);
      if (res.success) {
        const { imageUrl } = res.data;
        this.imageUrl = imageUrl;
        trackCompletePayment(this.queryParams.total );
        this.$router.replace({
          name: "success",
          query: { successType: SUCCESS.SLIP_UPLOAD },
        });
      } else Toast.fail(res.message);
      this.submitLoading = false;
    },
    afterRead(file) {
      if (!file) {
        Toast.fail("image select faild");
        return;
      }
      this.file = file;
    },
    copyInfo(info) {
      let oInput = document.createElement("input");
      oInput.value = info;
      document.body.appendChild(oInput);
      oInput.select(); // 选择对象;
      document.execCommand("Copy"); // 执行浏览器复制命令
      Toast.success(this.$t("复制成功"));
      oInput.remove();
    },
  },
};
</script>

<style lang="less" scoped>
.copy {
  color: #3399ff;
  display: inline-block;
  padding-left: 5px;
}

.upload-sample {
  display: inline-block;
  width: 250px;
  padding: 10px 0px;
  font-size: 0.9rem;
  text-align: right;
  color: #3399ff;
}

.saveButton {
  margin: 15px 15px 0 15px;
  padding-bottom: 15px;
  padding-top: 30px;
  text-align: center;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}

:deep(.van-cell__title) {
  -webkit-flex: none;
  -webkit-box-flex: none;
  flex: none;
}
</style>