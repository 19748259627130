import axios from '@/utils/request';

export async function uploadSlip(payload) {
    return axios.post('shopify/uploadSlip', payload, {
        'Content-Type': 'multipart/form-data'
    });
}

export async function fetchConfig() {
    return axios.get('fetchConfig');
}

